var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main-layout min-height"},[(_vm.pet)?_c('div',{staticClass:"pet-details-container"},[(_vm.pet.imgUrl)?_c('div',{staticClass:"pet-details-pic-container"},[_c('article',{staticClass:"article-img-select"},[_c('img',{staticClass:"img-details img-1",attrs:{"src":require('@/assets/images/animal/' + _vm.petImgSelected)}})]),_c('div',{staticClass:"pet-preview-selector"},[_c('span',{staticClass:"img-arrow hvr-rotate",on:{"click":function($event){return _vm.changeMainPic('left')}}},[_c('i',{staticClass:"fa-solid fa-left-long"})]),_c('article',{staticClass:"article-img-preview",on:{"click":function($event){return _vm.changeMainPic(0)}}},[_c('img',{staticClass:"img-details",attrs:{"src":require('@/assets/images/animal/' + _vm.petImg1)}})]),_c('article',{staticClass:"article-img-preview",on:{"click":function($event){return _vm.changeMainPic(1)}}},[_c('img',{staticClass:"img-details",attrs:{"src":require('@/assets/images/animal/' + _vm.petImg2)}})]),_c('article',{staticClass:"article-img-preview",on:{"click":function($event){return _vm.changeMainPic(2)}}},[_c('img',{staticClass:"img-details",attrs:{"src":require('@/assets/images/animal/' + _vm.petImg3)}})]),_c('span',{staticClass:"img-arrow hvr-forward",on:{"click":function($event){return _vm.changeMainPic('right')}}},[_c('i',{staticClass:"fa-solid fa-right-long"})])])]):_vm._e(),_c('div',{staticClass:"pet-bio-container"},[_c('div',{staticClass:"pet-bio"},[_c('h2',[_vm._v(_vm._s(_vm.pet.name)+"'s Story:")]),_c('p',[_vm._v(_vm._s(this.petBio))])]),_c('div',{staticClass:"pet-details-likes"},[_vm._v(" 🧡 Liked by "+_vm._s(_vm.pet.likes)+" Users 🧡 ")]),_c('div',{staticClass:"pet-properties"},[_c('div',{staticClass:"flex-left"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.pet.isVaccinated),expression:"pet.isVaccinated"}],class:{
              'pet-vaccinated pet-tag': _vm.pet.isVaccinated,
            }},[_vm._v(" Received Vaccines")]),_c('span',{staticClass:"pet-trained pet-tag"},[_vm._v(" Training: "+_vm._s(_vm.pet.trainedLevel))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.pet.isSafeWithChild),expression:"pet.isSafeWithChild"}],class:{
              'pet-safe pet-tag': _vm.pet.isSafeWithChild,
            }},[_vm._v(" Safe with Children")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.pet.isCastrated),expression:"pet.isCastrated"}],class:{
              'pet-castrated pet-tag': _vm.pet.isCastrated,
            }},[_vm._v(" Castrated")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.pet.isBirthCertificate),expression:"pet.isBirthCertificate"}],class:{
              'pet-birth-certificate pet-tag': _vm.pet.isBirthCertificate,
            }},[_vm._v(" Birth Certificate")])]),_c('div',{staticClass:"flex-right"},[_c('span',{class:{
              'pet-dog pet-tag': _vm.pet.type === 'dog',
              'pet-cat pet-tag': _vm.pet.type === 'cat',
              'pet-fish pet-tag': _vm.pet.type === 'fish',
              'pet-rabbit pet-tag': _vm.pet.type === 'rabbit',
              'pet-parrot pet-tag': _vm.pet.type === 'parrot',
            }},[_vm._v(" "+_vm._s(_vm.pet.breed))]),_c('span',{staticClass:"pet-age pet-tag"},[_vm._v(" "+_vm._s(_vm.pet.age)+" years old")]),_c('span',{staticClass:"pet-weight pet-tag"},[_vm._v(" size: "+_vm._s(_vm.pet.size))]),_c('span',{class:{
              'pet-gender-male pet-tag': _vm.pet.gender === 'male',
              'pet-gender-female pet-tag': _vm.pet.gender === 'female',
            }},[_vm._v(" "+_vm._s(_vm.pet.gender))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.pet.isPlayful),expression:"pet.isPlayful"}],class:{
              'pet-playful pet-tag': _vm.pet.isPlayful,
            }},[_vm._v(" Playful")])])]),_c('el-button',{staticClass:"adopt-btn hvr-grow-rotate",attrs:{"type":"success","round":""},on:{"click":_vm.adoptPet}},[_vm._v(" Adopt Now! ")]),_c('div',{staticClass:"pet-visit-share-container"},[_c('el-button',{staticClass:"pet-visit-owner-btn hvr-bounce-in",attrs:{"type":"info","round":""},on:{"click":_vm.showOwner}},[_vm._v("Visit Owner")]),_c('el-button',{staticClass:"pet-share-facebook hvr-wobble-skew",attrs:{"type":"info","round":""},on:{"click":_vm.shareOnFacebook}},[_vm._v("Share")])],1)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }